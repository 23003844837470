<template>
  <div>
    <div v-if="uploadError || internalError">
      <b-alert v-if="uploadError" show variant="danger" class="mb-2"> {{ uploadError }}</b-alert>
      <b-alert v-if="internalError" show variant="danger" class="mb-2"> {{ errorMessage }} </b-alert>
      <div class="flex justify-content-center">
        <LinkButton variant="outline" @click.native="reset()" class="h-11 mr-4 whitespace-nowrap">
          Try again
        </LinkButton>
      </div>
    </div>
    <div class="mb-4">
      <div v-if="image.src && isViewing" class="cropper__wrapper">
        <Cropper
          :src="image.src"
          :stencil-props="aspectRatio"
          ref="cropper"
          class="cropper"
          image-restriction="stencil"
        />
        <div class="mt-3 text-center cropper__button-group">
          <b-button class="cropper__upload" variant="success" v-if="image.src" @click="uploadImage()">Upload</b-button>
          <b-button class="cropper__reset" variant="outline-secondary" @click="reset()">Reset</b-button>
        </div>
      </div>
      <form enctype="multipart/form-data" novalidate v-else-if="isInitial">
        <div class="dropbox">
          <input
            type="file"
            name="image"
            :disabled="isSaving"
            @change="loadImage($event)"
            accept="image/jpeg,image/png"
            class="input-file"
          />
          <p v-if="isInitial">
            Drag an image here to upload<br />
            or click to browse
          </p>
        </div>
      </form>
      <div v-else-if="originalImage && !isSaving">
        <div class="image-container">
          <img :src="originalImage" class="image-preview" alt="Raffle Image" />
          <div class="image-clear">
            <b-button variant="outline-secondary" @click="clearImage">Clear Image</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper';
import LinkButton from '@/components/ui/LinkButton.vue';

import 'vue-advanced-cropper/dist/style.css';

const STATUS_INITIAL = 0,
  STATUS_VIEWING = 1,
  STATUS_UPLOADING = 2,
  STATUS_SUCCESS = 3,
  STATUS_FAILED = 4;

export default {
  components: {
    Cropper,
    LinkButton
  },
  props: {
    imageUrl: String,
    uploadSuccess: {
      type: Boolean,
      default: false
    },
    uploadError: {
      type: Error,
      default: null
    },
    aspectRatio: {
      type: Object,
      default: () => ({})
    },
    imageName: {
      type: String,
      default: null
    },
    maxFileSize: {
      type: Number,
      default: 2000000
    }
  },
  data() {
    return {
      errorMessage: '',
      internalStatus: null,
      internalError: null,
      image: {
        src: null,
        type: null,
        size: null,
        name: null
      }
    };
  },
  computed: {
    originalImage: {
      get: function () {
        return this.imageUrl;
      },
      set: function () {
        //
      }
    },
    currentStatus() {
      if (this.uploadSuccess) {
        return STATUS_SUCCESS;
      } else if (this.uploadError) {
        return STATUS_FAILED;
      } else {
        return this.internalStatus;
      }
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isViewing() {
      return this.currentStatus === STATUS_VIEWING;
    },
    isSaving() {
      return this.currentStatus === STATUS_UPLOADING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    isValid() {
      return this.currentStatus !== STATUS_VIEWING;
    }
  },
  methods: {
    reset() {
      // reset form to initial state
      this.originalImage = null;
      this.internalError = null;
      this.internalStatus = STATUS_INITIAL;
      this.image = {
        src: null,
        type: null,
        size: null,
        name: null
      };
      this.$emit('uploadImageError', null);
      this.$emit('error', null);
    },
    clearImage() {
      this.$emit('clearImage');
      this.reset();
    },
    uploadImage() {
      // Check if file size is over the max file size.
      if (this.image.size > this.maxFileSize) {
        this.errorMessage = `Image is too large. Please upload an image under ${this.maxFileSize / 1000000} MB.`;
        this.$emit('uploadImageError', new Error(this.errorMessage));
        return;
      }

      // Grab cropped section.
      const { canvas } = this.$refs.cropper.getResult();

      if (canvas) {
        // Add to form data and upload.
        canvas.toBlob((blob) => {
          this.internalStatus = STATUS_UPLOADING;
          this.$emit('uploadImage', { blob, name: this.image.name });
        }, this.image.type);
      }
    },
    loadImage(event) {
      const { files } = event.target;

      if (files?.[0]) {
        this.internalStatus = STATUS_VIEWING;

        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();

        reader.onload = () => {
          this.image = {
            src: blob,
            type: files[0].type,
            size: files[0].size,
            name: `${this.imageName}.${Math.floor(Date.now() / 1000)}`
          };
        };

        reader.readAsArrayBuffer(files[0]);
      } else {
        this.errorMessage = 'File not found';
        this.$emit('uploadImageError', new Error(this.errorMessage));
      }
    }
  },
  mounted() {
    if (!this.imageUrl) {
      this.internalStatus = STATUS_INITIAL;
    }
  }
};
</script>
<style scoped>
.dropbox {
  position: relative;
  margin: 0 10px;
  padding: 15px;
  width: calc(100% - 20px);
  height: 200px;
  color: #6c757d;
  background: white;
  border: 2px dashed #6c757d;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.dropbox:hover {
  background: #ddd;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(200px - 4px);
  opacity: 0;
  cursor: pointer;
}

.image-clear {
  display: inline-block;
}

.image-preview {
  display: inline-block;
  margin: 0 5px;
  max-width: 356px;
}

.cropper__button-group button {
  margin: 0 0.5rem;
}
</style>
<style>
.image-container {
  text-align: center;
}

.image-preview {
  margin-bottom: 15px;
}

.image-clear {
  display: block;
}
</style>
